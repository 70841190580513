export enum FEATURES {
  CurrencyExchangeRatesV3 = 'CurrencyExchangeRatesV3', // new logic of currency rates
  NotSameAnalyticsWithJournal = 'NotSameAnalyticsWithJournal', // old calendar logic
  RemoveFullType = 'removeFullType', // ?
  AnalyticOptimization = 'analyticOptimization', // calculate PL on mongo (now in golang proxy). NOT USED
  GolangProxy = 'Golang-proxy', // 100% NEED REMOVE
  FC609 = 'FC-609', // 100% NEED REMOVE
  ProrationOff = 'prorationOff', // research. Off. NEED REMOVE
  ShowStripeInvoice = 'SwowStripeInvoice', // 100% NEED REMOVE
  UseCurrencyRateProvider = 'useCurrencyRateProvider', // Use company currencyrate provider for rates. Off. NEED RESEARCH
  ExpiredBankSandbox = 'expiredBankSandbox', // Only for test. NEED REMOVE
  EmptyAccountsEndpoint = 'emptyAccountsEndpoint', // Not view allounts list if user has only "without account". Off. NEED RESEARCH
  GlobalCounterpartyList = 'globalCounterpartyList', // New counterparty logic for new companies
  UseAiApp = 'useAiApp', // use AI Asistant in settings. NEED RESEARCH
  UseRestriction = 'useRestriction', // restrictions for all entities in service. NEED RESEARCH
  UseNordigenV1 = 'useNordigenV1', // REMOVED IN CODE
  IgnoreFrontNordigenTakingDaysNumber = 'IgnoreFrontNordigenTakingDaysNumber', // Nordigen case when user get last operations list for small period
  NordigenFastIntegrate = 'NordigenFastIntegrate', // skip getting nordigen transactions first time
  NordigenRateLimites = 'NordigenRateLimites', // skip many additional data for integrations with rate limites
  NotRecalculateNordigenBalance = 'NotRecalculateNordigenBalance', // not recalculate nordigen integration balance
  WiseFeeDouble = 'WiseFeeDouble', // nordigen wise ignore operations with pattern fee and sum and date
  NewNordigenUrl = 'NewNordigenUrl', // 100% NEED REMOVE
  ShowSaltageUkraine = 'showSaltageUkraine', // NOT USED
  ShowSaltageSandbox = 'showSaltageSandbox', // NOT USED
  ShowSaltageApiOauth = 'showSaltageApiOauth', // NOT USED
  RealDeletePermissions = 'RealDeletePermissions', // delete (not archive) archived permissions from DB. FOR CRITICAL SITUATIONS
  UseReactivate = 'useReactivate', // research. Off. NEED REMOVE
  UnlimitedPayNow = 'unlimitedPayNow', // research. Off. NEED REMOVE
  OnboardingV2 = 'onboardingV2', // used for skipping onboarding
  LogApi = 'LogApi', // journal endpoints for external api
  AdvancedIntegrationComments = 'AdvancedIntegrationComments', // must be used for 100% and NEED REMOVE
  CheckboxOnSelfReceipts = 'CheckboxOnSelfReceipts', // checkbox  not selfReceipts transactions
  NotRecalculateBalance = 'NotRecalculateBalance', // checkbox not recalculate balance
  UpdateForPoster = 'UpdateForPoster', // 100% NEED REMOVE
  PrivateDeleteIntegration = 'PrivateDeleteIntegration', // if private status 401 or 403 - remove integration NEED RESEARCH
  MongoSearch = 'MongoSearch', // search by mongodb
  IgnoreCaseInRules = 'IgnoreCaseInRules', // ignore upper or lower cases in autorules. 100% NEED REMOVE
  DisableLimitOfAutoRulesCount = 'disableLimitOfAutoRulesCount', // Unlimited autorules
  UseNotAllowedCounterparties = 'UseNotAllowedCounterparties', // use "NOT" in reindexer filters. NEED RESEARCH
  OffsetForDateExternalApi = 'OffsetForDateExternalApi', // offset for external id
  TestFeature = 'testFeature', // only for test
  FairyPeriods = 'FairyPeriods', // periodId set 3 (by dates) when resheduled operation
  UseSolidgate = 'UseSolidgate',
  UseAiReports = 'UseAiReports',
  UseAiReportsVip = 'UseAiReportsVip',
  LocationsOnboarding = 'LocationsOnboarding',

  EmptyStateVideoVariant = 'emptyStateVideoVariant',
  NordigenMultiaccounts = 'nordigen_multiaccounts',
  ActiveTrialBadgeCoupon = 'activeTrialBadgeCoupon',
  EntitiesNumberReduce = 'EntitiesNumberReduce',
  OffIntegrationsForInactive = 'OffIntegrationsForInactive',
  FC808 = 'FC-808',
  testAB = 'test_AB',

  // //////////// CORE-UI ////////////////////
  ALLOW_CREATE_COMPANY_FROM_PREVIEW = 'allowCreateCompanyFromPreview',
  SHOW_PARTIAL_REPAYMENT_BUTTON = 'showPartialRepayment',
  SHOW_DEBIT_CREDIT_CHOOSE_HISTORY_PERIOD = 'showDebitCreditChooseHistoryPeriod',
  SHOW_FINTABLO_IMPORT = 'showFintabloImport',
  SHOW_1C_IMPORT = 'show1CImport',
  SHOW_GMAIL_SIGNUP = 'showGmailSignUp',
  SHOW_FACEBOOK_SIGNUP = 'showFacebookSignUp',
  SHOW_LINKEDIN_SIGNUP = 'showLinkedinSignUp',
  SHOW_TWITTER_SIGNUP = 'showTwitterSignUp',
  SHOW_DELETE_ACCOUNT = 'showDeleteAccount',
  SHOW_UNLINKCARD = 'showUnlinkCard',
  SHOW_CHANGE_PAYMENT_METHOD = 'showChangePaymentMethod',
  SHOW_PAY_NOW = 'showPayNow',
  SHOW_DOWNLOAD_PAYMENT = 'showDownloadPayment',
  SHOW_PAYMENT_HISTORY = 'showPaymentHistory',
  SHOW_DOWNLOAD_PAYMENT_RECEIPT = 'showDownloadPaymentReceipt', // Off NEED REMOVE
  SHOW_NEXT_PAYMENT = 'showNextPayment',
  USE_CLEAR_FILTER_FIELDS = 'useClearFilterFields',
  SHOW_SUBSCRIPTION_LEFT_DAYS = 'showSubscriptionLeftDays',
  SHOW_ADDITIONAL_INFO_ON_IMPORT_DIALOG = 'showAdditionInfoOnImportDialog',
  USE_NEW_UX_LOGIC_CREATE_ITEM = 'useNewUXLogicCreateItem',
  SHOW_SUGGEST_TARIFF_LABEL = 'showSuggestTariffLabel',
  SHOW_GROW_SURF_REFERRAL = 'showGrowsurfRefferal',
  OPEN_REFERRAL_LINK_FROM_DEEP_LINK = 'openReferralLinkFromDeepLink', // not user NEED REMOVE
  SHOW_DETAILS_REFERRAL_LINK = 'showDetailsReferralLink',
  SHOW_DOWNLOAD_ITEMS_BUTTON = 'showDownloadItemsButton',
  OPERATION_TO_TRANSFER = 'operationToTransfer',
  EXPORT_CHARTS_TO_PDF = 'exportChartsToPDF', // not user NEED REMOVE
  USE_PROJECTS_CHARTS = 'useProjectsCharts',
  USE_CALENDAR_AIRBNB_VERSION = 'useCalendarAirbnbVersion',
  SHOW_INVOICING_MENU = 'showInvoicingMenu',
  ADD_INTERCOM_CONTACT = 'addIntercomContactAtFront', // not user NEED REMOVE
  SHOW_FILTERS_IN_CALENDAR = 'FiltersInCalendar',
  SHOW_TC_MODAL = 'showTCModal',
  SHOW_INVOICING_EMPTY_STATE = 'showInvoicingEmptyState',
  SHOW_SETUP_AND_IMPLEMENT = 'showSetupAndImplement',
  SHOW_MAIN_CATEGORY_AS_NESTED_CATEGORY = 'showMainCategoryAsNestedCategory',
  USE_SETTINGS_V2 = 'useSettingsV2',
  USE_CHRONOS_DECISION = 'useChronosdecision',
  SHOW_SUBSCRIPTION_ACTIVE_TRIAL_BADGE = 'showSubscriptionActiveTrialBadge',
  SHOW_SUBSCRIPTION_TRIAL_BADGE_V2 = 'showSubscriptionTrialBadgeV2',
  USE_EMOJI_INSTEAD_OF_AMOUNT = 'useEmojiInsteadOfAmount',
  USE_DATE_OF_PAYMENT_AS_PERIOD = 'useDateOfPaymentAsPeriod',
  USE_SORT_CATEGORIES_V2 = 'useSortCategoriesV2',
  SHOW_CASH_GAP = 'showCashGap',
  SHOW_ADDITIONAL_TIME_PERIODS = 'showAdditionalTimePeriods',
  SHOW_SETTINGS_CUSTOMIZATION = 'showSettingsCustomization',
  USE_LOGIN_BY_QR_CODE = 'useLoginByQRCode',
  USE_SORT_ANALYTIC_MAIN_SCREEN = 'useSortAnalyticMainScreen',
  SHOW_VIDEO_LINKS = 'showVideoLinks',
  SHOW_INTEGRATION_BUTTON = 'showIntegrationButton',
  SHOW_FULL_SCREEN_BUTTON = 'showFullScreenIcon',
  SHOW_BLACK_FRIDAY_DISCOUNT = 'showBlackFridayDiscount',
  SHOW_AUTO_RULES_SECTION = 'showAutoRulesSection',
  USE_CURRENCY_PROVIDER = 'useCurrencyProvider',
  EMPTY_STATES_V3 = 'emptyStatesV3',
  SHOW_UZBEK_PAYMENT_BANNER = 'showUzbekPaymentBanner',
  VIEW_AUTO_RULE_MULTI_VAR = 'viewAutoRuleMultiVar',
  LONG_UPDATE_OPERATION = 'LongUpdateOperation',
  SHOW_MONOBANK_IMPORT = 'showMonobankImport',
  NEW_USER_FOR_CLIENT_AND_CATEGORIES_VIEW = 'newUserForClientAndCategoriesView',
  CALENDAR_COPY_ACCOUNTS = 'CalendarCopyAccounts',
  SHOW_BALANCE_PAGE = 'ShowBalancePage',
  REMOVE_CURRENCY_RATE_DIFF = 'RemoveCurrencyRateDiff',
  SHOW_AMOUNT_FIELD_CALCULATOR = 'showAmountFieldCalculator',
  USE_SEARCH_BUTTON = 'UseSearchButton',
  SHOW_UPDATE_JOURNAL_BUTTON = 'ShowUpdateJournalButton',

  // INTEGRATIONS
  SHOW_URKSIB_BANK_INTEGRATION = 'showUkrsibBankIntegration',
  SHOW_BANK_MILLENNIUM_INTEGRATION = 'showBankMillenniumIntegration',
  SHOW_BANK_EBURY_INTEGRATION = 'showBankEburyIntegration',
  SHOW_BANK_ING_INTEGRATION = 'showBankINGIntegration',
  SHOW_BANK_INTELIGO_INTEGRATION = 'showBankInteligoIntegration',
  SHOW_NEST_BANK_CORPORATE_INTEGRATION = 'showNestBankCorporateIntegration',
  SHOW_NEST_BANK_PRIVATE_INTEGRATION = 'showNestBankPrivateIntegration',
  SHOW_SANTANDER_BANK_INTEGRATION = 'showSantanderBankIntegration',
  SHOW_SANTANDER_BANK_CORPORATE_INTEGRATION = 'showSantanderBankCorporateIntegration',
  SHOW_STRIPE_BANK_INTEGRATION = 'showStripeBankIntegration',
  SHOW_ING_BANK_FOR_POLAND = 'showINGbankForPoland', // not user NEED REMOVE
  SHOW_ING_SALTEDGE = 'showINGsaltedge',
  SHOW_TEST_SALTEDGE_BANKS = 'showTestSaltedgeBanks',
  SHOW_FACTUROWNIA_INTEGRATION = 'showFacturowniaIntegration',
  USE_RECONNECT_INTEGRATION = 'useReconnectIntegration',
  USE_ONLY_NORDIGEN_SERVICE = 'useOnlyNordigenService',
  SHOW_IDOKLAD_INTEGRATION = 'showiDokladIntegration',
  SHOW_ALERT_INTEGRATION_PAYMENT = 'showAlertIntegrationPayment',
  SHOW_DEMO_NORDIGEN_BANK_ON_STAGE = 'showDemoNordigenBankOnStage',
  SHOW_MONO_ACQUIRING_INTEGRATION = 'showMonoAcquiringIntegration',
  SHOW_TABLE_INSTEAD_OF_CHART = 'showTableInsteadOfChart',
  SHOW_ANIMATED_VIDEO_ICON = 'showAnimatedVideoIcon',
  SHOW_COINS_SWITCHER = 'showCoinsSwitcher',

  // LANGUAGES
  SHOW_CZECH_LANGUAGE = 'showCzechLanguage',
  SHOW_PORTUGUESE_LANGUAGE = 'showPortugueseLanguage',
  SHOW_FRENCH_LANGUAGE = 'showFrenchLanguage',
  SHOW_SPANISH_LANGUAGE = 'showSpanishLanguage',
  SHOW_ITALIAN_LANGUAGE = 'showItalianLanguage',
  SHOW_TURKISH_LANGUAGE = 'showTurkishLanguage',
  SHOW_KAZAKH_LANGUAGE = 'showKazakhLanguage',
  SHOW_RUSSIAN_LANGUAGE = 'showRussianLanguage',
  SHOW_UZBEK_LANGUAGE = 'showUzbekLanguage',
  SHOW_BRAZIL_LANGUAGE = 'showBrazilLanguage',
}

export enum EmptyInvoicingStateVariant {
  showVideo = 'showVideo',
  withoutVideo = 'withoutVideo',
}
