import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class BankaiParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions:
        {
          "defaultCurrency": "UAH",
          "withoutEmpty": true,
          "isCurCase": [
            [
              0,
              {
                "in": ""
              }
            ]
          ],
          "proceedCaseConfig": {
            "withoutEmpty": true,
            "delete": [
              {
                "to": [
                  [
                    0,
                    {
                      "eq": "Дата"
                    }
                  ],
                  [
                    1,
                    {
                      "in": "док"
                    }
                  ],
                  [
                    2,
                    {
                      "eq": "Отправитель/Получатель"
                    }
                  ]
                ],
                "count": 1
              },
              {
                "from": [
                  [
                    0,
                    {
                      "in": "Остаток"
                    }
                  ]
                ],
                "to": [
                  [
                    0,
                    {
                      "in": "Остаток"
                    }
                  ]
                ],
                "count": 999
              },
              {
                "from": [
                  [
                    0,
                    {
                      "in": "Исходящий остаток"
                    }
                  ]
                ],
                "count": 1
              }
            ],
            "fields": {
              "date": {
                "column": [
                  "Дата",
                  0
                ],
                "dateFormat": "DD.MM.YYYY"
              },
              "credit": {
                "column": [
                  "Пополнение",
                  4
                ]
              },
              "debit": {
                "column": [
                  "Списание",
                  5
                ]
              },
              "comment": {
                "column": [
                  "Детализация",
                  3
                ]
              },
              "counterparty": {
                "column": [
                  "Отправитель/Получатель",
                  2
                ]
              }
            }
          }
        }
      }
    ],
  };
}
