import { makeStyles } from '@material-ui/core/styles';

import { catskillWhite, codGray } from '../../../../../theme/colors';

export const useStyles = makeStyles({
  button: {
    width: 198,
    marginBottom: 24,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
  },
  rowInfo: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 5,
    marginTop: 24,
  },
  rowWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: 5,
    paddingRight: 5,
    justifyContent: 'space-between',
    '&:hover': {
      background: catskillWhite,
      cursor: 'pointer',
      borderRadius: 8,
    },
  },
  rowInfoWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: 5,
    paddingRight: 5,
    '&:hover': {
      background: catskillWhite,
      cursor: 'pointer',
      borderRadius: 8,
    },
  },
  block: {
    display: 'flex',
    alignItems: 'center',
  },
  margin16: {
    marginRight: 16,
  },
  tooltipRoot: {
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: '12px !important',
    padding: '0 20px !important',
    opacity: '1 !important',
    height: 45,
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltipText: {
    fontSize: 13,
    lineHeight: '20px',
  },
  questionIcon: {
    marginRight: 22,
    width: 20,
    height: 20,
    opacity: 0.5,
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
  },
  withoutDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    fontSize: 28,
    lineHeight: '36px',
    color: codGray,
    fontWeight: 700,
    marginBottom: 16,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  marginBottom: {
    marginBottom : 20
  }
});
