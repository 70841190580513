import { Typography } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';

import CheckmarkIcon from '../../../../../assets/images/svg/checkmark.svg';
import CopyIcon from '../../../../../assets/images/svg/copy.svg';
import QuestionIcon from '../../../../../assets/images/svg/question.svg';
import CustomButton from '../../../../../components/Button';
import Dialog from '../../../../../components/Dialog/Dialog';
import companyActions from '../../../../../store/company/actions';
import {
  getApiKeys,
  selectCurrentCompany,
} from '../../../../../store/company/selectors';
import { ApiKey } from '../../../../../store/company/types';
import { showError } from '../../../../../utils/showError';
import { isLite } from '../../../../../utils/subscriptions';
import SubscriptionDialog from '../../../../Subscriptions/SubscriptionChooseDialog';
import CreateApiDialog from './CreateApiKey';
import EditApiKeyDialog from './InfoApiKey';
import { useStyles } from './styles';

export type Props = {
  showInDialog?: boolean;

  onClose?(): void;
};

function ApiDialog(props: Props) {
  const { onClose, showInDialog = true } = props;
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [currentApiKey, setCurrentApiKey] = useState<ApiKey | null>(null);
  const [copied, setCopied] = useState<{ [key in string]: boolean }>({});
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const apiKeys = useSelector(getApiKeys);
  const company = useSelector(selectCurrentCompany);

  const handleCloseSubscriptionDialog = useCallback(() => {
    setShowSubscriptionDialog(false);
  }, []);

  const handleCopied = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      const { id } = event.currentTarget;

      try {
        const copiedKey = apiKeys.find((el) => el._id === id);

        if (copiedKey) {
          setCopied(() => ({
            [id]: true,
          }));

          copy(copiedKey.key);

          setTimeout(() => {
            setCopied({});
          }, 2000);
        }
      } catch (e) {
        showError(e);
      }
    },
    [apiKeys],
  );

  const handleOpenEditApiDialog = useCallback(
    (event: SyntheticEvent) => {
      const { id } = event.currentTarget;
      const apiKey = apiKeys.find((el) => el._id === id);

      if (apiKey) {
        setCurrentApiKey(apiKey);
      }

      setShowEditDialog(true);
    },
    [apiKeys],
  );

  const handleCloseEditApiDialog = useCallback(() => {
    setShowEditDialog(false);
  }, []);

  const handleOpenCreateApiDialog = useCallback(() => {
    if (isLite(company)) {
      setShowSubscriptionDialog(true);

      return;
    }

    setShowCreateDialog(true);
  }, [company]);

  const handleCloseCreateApiDialog = useCallback(() => {
    setShowCreateDialog(false);
  }, []);

  const getTooltipContent = useCallback(
    () => (
      <Typography className={classes.tooltipText}>
        {Object.keys(copied).length
          ? t('telegramBot.madeCopy')
          : t('telegramBot.makeCopy')}
      </Typography>
    ),
    [copied, t, classes],
  );

  useEffect(() => {
    dispatch(companyActions.getApiKeys());
  }, [dispatch]);

  const Content = useCallback(
    () => (
      <div className={classes.withoutDialogContainer}>
        <CustomButton
          action={handleOpenCreateApiDialog}
          title={t('api.add')}
          className={classes.button}
        />
        <>
          {apiKeys.map((el) => (
            <div
              id={el._id}
              key={el._id}
              className={classes.row}
              onClick={handleOpenEditApiDialog}
            >
              <div className={classes.rowWrapper}>
                <div className={classes.block}>
                  <Typography className={classes.margin16}>
                    {el.name}
                  </Typography>
                </div>
                <div className={classes.block}>
                  <div
                    id={el._id}
                    onClick={handleCopied}
                    data-for="copyKey"
                    data-tip
                  >
                    {copied[el._id] ? (
                      <img src={CheckmarkIcon} alt="checkmark" />
                    ) : (
                      <img src={CopyIcon} alt="copy" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* @ts-ignore */}
          <ReactTooltip
            id="copyKey"
            className={classes.tooltipRoot}
            effect="solid"
            type="light"
            place="top"
            delayShow={500}
            getContent={getTooltipContent}
          />
        </>
        <div className={classes.rowInfo}>
          <a
            className={classes.rowInfoWrapper}
            href="https://api.finmap.online"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.questionIcon}
              src={QuestionIcon}
              alt="question"
            />
            <Typography className={classes.text}>
              {t('api.docTitle')}
            </Typography>
          </a>
        </div>
      </div>
    ),
    [
      t,
      copied,
      apiKeys,
      classes,
      handleCopied,
      getTooltipContent,
      handleOpenEditApiDialog,
      handleOpenCreateApiDialog,
    ],
  );

  return (
    <>
      {showInDialog && (
        <Dialog title={t('api.title')} onClose={onClose} isOpened>
          <Content />
        </Dialog>
      )}

      {!showInDialog && (
        <div className={classes.column}>
          <Typography className={classes.title}>
            {t('settingsPage.apiTitle')}
          </Typography>
          <Typography className={cn(
            classes.marginBottom,
            classes.text)}
            component='div'>
            {t('settingsPage.apiDescription')
              .split('\n')
              .map((line, index) => (
                <span key={index} style={{ display: 'block', marginBottom: 8 }}>
                  {line}
                </span>
              ))
            }
          </Typography>
          <Content />
        </div>
      )}
      {showSubscriptionDialog && (
        <SubscriptionDialog
          title={t('subscription.warnings.getApiAccess')}
          onClose={handleCloseSubscriptionDialog}
        />
      )}
      {showEditDialog && (
        <EditApiKeyDialog
          apiKey={currentApiKey}
          onClose={handleCloseEditApiDialog}
        />
      )}
      {showCreateDialog && (
        <CreateApiDialog onClose={handleCloseCreateApiDialog} />
      )}
    </>
  );
}

export default React.memo(ApiDialog);
