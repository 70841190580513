import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class TbiParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions:
          {
  "defaultCurrency": "EUR",
  "isCurCase": [
    [
      0,
      {
        "in": ""
      }
    ]
  ],
  "proceedCaseConfig": {
    "removeOnlyEmpty": true,
    "delete": [
      {
        "to": [
          [
            0,
            {
              "eq": "Счетоводна дата"
            }
          ],
          [
            1,
            {
              "eq": "Вальор"
            }
          ]
        ],
        "count": 1
      }
    ],
    "fields": {
      "debit": {
        "column": [
          "Дебит",
          9
        ]
      },
      "credit": {
        "column": [
          "Кредит",
          10
        ]
      },
      "dateAndTime": {
        "column": [
          "Дата / час",
          11
        ],
        "dateFormat": "YYYY-MM-DD HH:mm:ss"
      },
      "comment": {
        "column": [
          "Основание",
          2
        ],
        "add": [
          " ",
          {
            "column": [
              "Вид на трансакцията",
              5
            ]
          }
        ]
      },
      "counterparty": {
        "column": [
          "Наредител/Получател",
          3
        ]
      }
    }
  }
}
      }
    ],
  };
}