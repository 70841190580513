import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class RaiffeisenImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            CSVDelimiter: ';',
          },
          isCurCase: [
            [
              0,
              {
                in: 'Дата операції',
              },
            ],
          ],
          proceedCaseConfig: {
            fields: {
              currency: {
                column: ['Валюта операції', 7],
              },
              dateAndTime: {
                column: ['Дата операції', 0],
                dateFormat: 'DD.MM.YYYY, HH:mm',
              },
              sum: {
                column: ['Сума у валюті рахунку', 5],
              },
              comment: {
                column: ['Деталі операції', 4],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          encoding: 'win1251',
          preParserConfigs: {
            CSVDelimiter: ';',
          },
          proceedCaseConfig: {
            delete: [
              {
                from: [
                  [
                    13,
                    {
                      eq: '0.00',
                    },
                  ],
                ],
                to: [
                  [
                    13,
                    {
                      eq: '0.00',
                    },
                  ],
                ],
                count: 99999,
              },
              {
                from: [
                  [
                    14,
                    {
                      eq: '0.00',
                    },
                  ],
                ],
                to: [
                  [
                    14,
                    {
                      eq: '0.00',
                    },
                  ],
                ],
                count: 99999,
              },
            ],
            fields: {
              currency: {
                column: ['Валюта', 3],
              },
              dateAndTime: {
                column: ['Дата операції', 4],
              },
              counterparty: {
                column: ['Кореспондент', 10],
              },
              debit: {
                column: ['Дебет', 13],
              },
              credit: {
                column: ['Кредит', 14],
              },
              comment: {
                column: ['Призначення платежу', 15],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                in: 'АТ «Райффайзен Банк»',
              },
            ],
          ],
          preParserConfigs: {
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      in: 'Дата операції',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      in: 'рахунку)',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      nextWord: {
                        in: 'Дата операції',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  isNum: true,
                  replace: [
                    {
                      from: ' ',
                      to: '',
                    },
                    {
                      from: ',',
                      to: '.',
                    },
                  ],
                },
                {
                  length: 3,
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Дата операції\n/Дата обробки\nоперації', 0],
                replaceOne: [
                  {
                    from: {
                      regexp: {
                        str: '/[^_]+',
                        flags: 'm',
                      },
                    },
                    to: '',
                  },
                ],
              },
              sum: {
                column: ['Сума у валюті\nрахунку', 5],
              },
              comment: {
                column: ['Деталі операції', 3],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                in: 'АТ "РАЙФФАЙЗЕН БАНК"',
              },
            ],
          ],
          preParserConfigs: {
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      in: 'Номер',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      in: 'док.',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        in: 'док.',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        in: 'ВСЬОГО:',
                      },
                    },
                  ],
                },
              ],
              define: [
                {
                  regexp: {
                    str: '^([\\d\\s]+\\.\\d{2})$',
                  },
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата опер.', 0],
                replaceOne: [
                  {
                    from: {
                      regexp: {
                        str: '\\s',
                      },
                    },
                    to: ' ',
                  },
                ],
              },
              counterparty: {
                column: ['Реквізити кореспондента', 4],
              },
              debit: {
                column: ['Дебет', 2],
              },
              credit: {
                column: ['Кредит', 3],
              },
              comment: {
                column: ['Призначення платежу', 5],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                eq: 'АТ "Райффайзен Банк"',
              },
            ],
            [
              31,
              {
                eq: 'Рахунок/Назва Банку',
              },
            ],
          ],
          preParserConfigs: {
            maxInterlineSpacingPx: 17,
            interlineSpacingAccuracy: 8,
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      in: 'Реквізити кореспондента',
                    },
                  },
                ],
                to: [
                  {
                    or: [
                      {
                        word: {
                          in: 'Найменування',
                        },
                      },
                      {
                        word: {
                          eq: 'Назва',
                        },
                      },
                    ],
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      or: [
                        {
                          prevWord: {
                            in: 'Найменування',
                          },
                        },
                        {
                          prevWord: {
                            eq: 'Назва',
                          },
                        },
                      ],
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      nextWord: {
                        in: 'Обороти за період',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  isNum: true,
                },
                {
                  in: ',',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Дата\nдокументу', 1],
              },
              comment: {
                column: ['Призначення платежу', 5],
              },
              debit: {
                column: ['Витрати\n(дебет)', 6],
              },
              credit: {
                column: ['Надходження\n(кредит)', 7],
              },
              counterparty: {
                column: ['Назва', 4],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          "defaultCurrency": "UAH",
          "isCurCase": [
            [
              0,
              {
                "eq": "АТ \"Райффайзен Банк\""
              }
            ],
            [
              21,
              {
                "eq": "Номер"
              }
            ],
            [
              22,
              {
                "eq": "Дата"
              }
            ]
          ],
          "preParserConfigs": {
            "maxInterlineSpacingPx": 13,
            "interlineSpacingAccuracy": 8,
            "verticalAlign": "middle",
            "prepareRawConfig": {
              "findHeader": {
                "from": [
                  {
                    "word": {
                      "eq": "Номер"
                    }
                  }
                ],
                "to": [
                  {
                    "word": {
                      "eq": "Найменування"
                    }
                  }
                ]
              },
              "delete": [
                {
                  "to": [
                    {
                      "word": {
                        "eq": "Найменування"
                      }
                    }
                  ],
                  "count": 1
                },
                {
                  "from": [
                    {
                      "word": {
                        "in": "Обороти за період"
                      }
                    }
                  ]
                }
              ],
              "define": [
                {
                  "dateFormat": "HH:mm"
                }
              ]
            }
          },
          "proceedCaseConfig": {
            "fields": {
              "dateAndTime": {
                "column": [
                  "Дата\nоперації",
                  1
                ],
                "replace": [
                  {
                    "from": "\n",
                    "to": " "
                  }
                ],
                "add": [
                  ":00"
                ]
              },
              "comment": {
                "column": [
                  "Призначення платежу",
                  4
                ]
              },
              "debit": {
                "column": [
                  "Витрати\n(дебет)",
                  5
                ]
              },
              "credit": {
                "column": [
                  "Надходження\n(кредит)",
                  6
                ]
              }
            }
          }
        },
      },
      {
        proceedCase: (importDocument) => {
          const body = [];
          importDocument
            .slice(1)
            .filter(
              (arr) =>
                !arr[0]?.includes('Оборот за ') &&
                !arr[0]?.includes('Вихідний залишок на '),
            )
            .forEach((el, i) => {
              const [dateRaw] = el[1].split('\n');
              const [date] = dateRaw.split(' ');
              if (!moment(date, 'DD.MM.YYYY', true).isValid()) {
                const lastIndex = body.length - 1;
                if (el[0]) body[lastIndex][0] += `\n${el[0]}`;
                if (el[1]) body[lastIndex][1] += `\n${el[1]}`;
                if (el[2]) body[lastIndex][2] += `\n${el[2]}`;
                if (el[3]) body[lastIndex][3] += `\n${el[3]}`;
                if (el[4]) body[lastIndex][4] += `\n${el[4]}`;
                if (el[5]) body[lastIndex][5] += `\n${el[5]}`;
                if (el[6]) body[lastIndex][6] += `\n${el[6]}`;
              } else {
                body.push(el);
              }
            });
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => {
            const [date, time] = this.getFirstValidCellByColumn([
              'Дата\nоперації',
              'Дата операції',
              1,
            ])?.split('\n');
            let dateAndTime = date;
            if (time) {
              dateAndTime += ` ${time}`;
            }
            return {
              dateAndTime,
              comment: this.getFirstValidCellByColumn([
                'Призначення платежу',
                3,
              ]),
              debit: this.getFirstValidCellByColumn(['Витрати\n(дебет)']),
              credit: this.getFirstValidCellByColumn([
                'Витрати\n(кредит)',
                'Надходження\n(кредит)',
              ]),
              counterparty: this.getFirstValidCellByColumn([4]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            maxInterlineSpacingPx: 10,
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Реквізити кореспондента' } }],
                to: [
                  {
                    or: [
                      { word: { in: 'Найменування' } },
                      { word: { eq: 'Назва' } },
                    ],
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      or: [
                        { prevWord: { in: 'Найменування' } },
                        { prevWord: { eq: 'Назва' } },
                      ],
                    },
                  ],
                  count: 1,
                },
                {
                  from: [{ nextWord: { in: 'Обороти за період' } }],
                  count: 1,
                },
              ],
              define: [{ regexp: { str: '^([\\s\\d]+,\\d+)$' } }],
            },
          },
        },
      },
    ],
  };
}
