import { makeStyles } from '@material-ui/core/styles';

import {
  aquaHaze,
  black,
  catskillWhite,
  white,
} from '../../../../../theme/colors';

export const useCategoriesStyles = makeStyles({
  createButton: {
    width: 211,
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '18px 0',
    flex: 1,
    cursor: 'pointer',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
    '&:hover': {
      background: catskillWhite,
      borderRadius: 8,
    },
  },
  block: {
    display: 'flex',
    alignItems: 'center',
  },
  sortIcon: {
    marginRight: 22,
  },
  price: {
    marginRight: 45,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.32,
    borderRight: '0.5px solid rgba(0, 0, 0, 0.2)',
  },
  sortableList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingLeft: 8,
    overflow: 'scroll',
    width: 200,
  },
  leftBlock: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: `calc(100% - 25px)`,
  },
  margin16: {
    marginRight: 16,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 24,
  },
  button: {
    minWidth: 160,
    paddingLeft: 24,
    paddingRight: 24,
  },
  paddingBt36: {
    paddingBottom: 36,
  },
  menuItemWrapperRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 12,
    paddingRight: 12,
    marginLeft: 0,
    marginRight: 8,
    height: 48,
    fontSize: 15,
    lineHeight: '20px',
    color: black,
    cursor: 'pointer',
    background: white,
    '&:hover': {
      borderRadius: 8,
      background: aquaHaze,
    },
  },
  menuItemWrapperRowActive: {
    background: aquaHaze,
    borderRadius: 8,
  },
  rotateAttentionIcon: {
    transform: "rotate(180deg)",
  },
});
