import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class UkreximbankParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Загальні реквізити',
              },
            ],
            [
              1,
              {
                eq: 'Реквізити кореспондента',
              },
            ],
          ],
          proceedCaseConfig: {
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Код',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Дата архівування',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              dateAndTime: {
                column: ['Дата проведення', 8],
              },
              credit: {
                column: ['Сума', 6],
                if: {
                  eq: [
                    {
                      column: ['Тип', 2],
                    },
                    'К',
                  ],
                },
              },
              debit: {
                column: ['Сума', 6],
                if: {
                  eq: [
                    {
                      column: ['Тип', 2],
                    },
                    'Д',
                  ],
                },
              },
              comment: {
                column: ['Призн.платежу', 12],
              },
              counterparty: {
                column: ['Найменування кореспондента', 18],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Наш рахунок',
              },
            ],
            [
              1,
              {
                eq: 'Наш IBAN',
              },
            ],
          ],
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Дата проведення', 9],
                dateFormat: 'DD/MM/YYYY',
              },
              credit: {
                column: ['Сума', 11],
                if: {
                  eq: [
                    {
                      column: ['Операція', 2],
                    },
                    'Кредит',
                  ],
                },
              },
              debit: {
                column: ['Сума', 11],
                if: {
                  eq: [
                    {
                      column: ['Операція', 2],
                    },
                    'Дебет',
                  ],
                },
              },
              comment: {
                column: ['Призначення платежу', 8],
              },
              counterparty: {
                column: ['Найменування контр.', 6],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          "defaultCurrency": "UAH",

          "isCurCase": [
            [
              0,
              {
                "in": ""
              }
            ]
          ],
          "proceedCaseConfig": {
            "removeOnlyEmpty": true,
            "delete": [
              {
                "to": [
                  [
                    0,
                    {
                      "eq": "Номер"
                    }
                  ],
                  [
                    10,
                    {
                      "eq": "Дебет"
                    }
                  ]
                ],
                "from": [
                  [
                    5,
                    {
                      "eq": "Тип виписки:"
                    }
                  ],
                  [
                    12,
                    {
                      "eq": "Усі документи"
                    }
                  ]
                ],
                "count": 1
              },
              {
                "from": [
                  [
                    0,
                    {
                      "eq": "Номер"
                    }
                  ]
                ],
                "to": [
                  [
                    43,
                    {
                      "eq": "Дата проводки"
                    }
                  ]
                ],
                "count": 999
              },
              {
                "from": [
                  [
                    3,
                    {
                      "eq": "Обороти:"
                    }
                  ]
                ],
                "count": 1
              }
            ],
            "fields": {
              "debit": {
                "column": [
                  "Дебет",
                  10
                ]
              },
              "credit": {
                "column": [
                  "Кредит",
                  16
                ]
              },
              "dateAndTime": {
                "column": [
                  "Дата проводки",
                  43
                ],
                "replace":[
                  {
                    "from": "  ",
                    "to": ""
                  }
                ]
              },
              "comment": {
                "column": [
                  "Деталі платежу",
                  36
                ]
              },
              "counterparty": {
                "column": [
                  "Кореспондент",
                  24
                ]
              }
            }
          }
        }
      },
    ],
  };
}
